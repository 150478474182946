import '../css/app.css'
//import * as Sentry from '@sentry/vue'
import axios from 'axios'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import toast from '@/Plugins/toast'
import Notifications from 'notiwind'
import { createPinia } from 'pinia'
import 'vue3-carousel/dist/carousel.css'
import '@github/markdown-toolbar-element'
import { Mentionable } from 'vue-mention'

const pinia = createPinia()

axios.defaults.withCredentials = true

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { modal } from 'momentum-modal'

createInertiaApp({
    title: (title) => title ? `${title} - Codecourse` : `Codecourse`,
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue')
        return pages[`./Pages/${name}.vue`]()
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        // Sentry.init({
        //     app,
        //     dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        //     enableTracing: false,
        // })
        //
        // if (props.initialPage.props.auth?.user) {
        //     Sentry.setUser({
        //         id: props.initialPage.props.auth.user.id,
        //         email: props.initialPage.props.auth.user.email,
        //     })
        // }

        app.use(plugin)
            .use(toast)
            .use(pinia)
            .use(Notifications)
            .use(modal, {
                resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
            })
            .component('Mentionable', Mentionable)

        app.mount(el)
    },
    progress: {
        color: 'rgba(107,96,255,1)',
        delay: 300,
        showSpinner: true
    },
})
