import { router, usePage } from '@inertiajs/vue3'
import { notify } from 'notiwind'
import { computed } from 'vue'

const page = usePage()

export default () => {
    router.on('finish', () => {
        let toast = page.props.toast

        if (toast) {
            notify({
                body: toast
            }, 4000)

            page.props.toast = null
        }
    })
}
